import React from 'react';

const AboutUs = () => {
  return (
    <div id="about" className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="text-700 text-center">
        <div className="text-yellow-800 font-bold text-5xl mb-3" id="about">
          About NorthEast Express
        </div>
        <div className="text-700 text-2xl mb-5" data-aos="flip-up" data-aos-duration="1000">
          NorthEast Express is a family owned company which has been offering truck dispatching among other services for owner operators and truck fleets since 2012. NorthEast was created with a goal; to help trucking companies stay profitable and save valuable time by providing cost effective dispatch and back-office adminstration.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
