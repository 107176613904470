/* eslint-disable max-statements */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import firebase from 'gatsby-plugin-firebase';
import { connect } from 'react-redux';
import TextTransparent from '../inputs/TextTransparent';

const EnquireForm = ({ toast }) => {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      if (inputs.company && inputs.email && inputs.phone) {
        await firebase.firestore().collection('enquiries').add({ ...inputs, timestamp: new Date(Date.now()) });
        ev.target.reset();
        toast.current.show({
          severity: 'success',
          summary: 'Successfully Sent!',
          detail: 'Your enquiry has been sent successfully. We\'ll get back to you as soon as possible',
          life: 5000
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Missing Details',
          detail: 'Please make sure to fill in your company, email and phone',
          life: 5000
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  return (
    <form className="grid formgrid p-fluid" onSubmit={handleSubmit}>
      <TextTransparent name="company-input" label="Company" getValue={(value) => setInputs({ ...inputs, company: value })} />
      <TextTransparent name="name-input" label="Email" getValue={(value) => setInputs({ ...inputs, email: value })} />
      <TextTransparent name="phone-input" label="Phone" getValue={(value) => setInputs({ ...inputs, phone: value })} />
      <Button label="Enquire" type="submit" loading={loading}
      className="block mt-2 mb-2 lg:mb-0 p-button-rounded p-button-warning p-button-lg font-medium" />
    </form>
  );
};

const mapStateToProps = (state) => ({
  toast: state.auth.toast
});

export default connect(mapStateToProps)(EnquireForm);
