import React, { useEffect, Suspense, lazy } from 'react';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import AOS from 'aos';
import AboutUs from '../components/AboutUs';
import IntroHeader from '../components/intro-header/IntroHeader';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Services = lazy(() => import('../components/services/Services'));
const Benefits = lazy(() => import('../components/services/Benefits'));
const Specialties = lazy(() => import('../components/services/Specialties'));

const IndexPage = ({ toast }) => {
  useEffect(() => {
    AOS.init({ once: true });
    return () => null;
  }, []);
  return (
    <Layout>
      <Seo title="Home" />
      <Toast ref={toast} position="top-right" className="text-left m-w-300" />
      <IntroHeader />
      <AboutUs />
      <Suspense fallback={<div style={{ minHeight: '100vh' }} className="w-100 d-flex center">
        <i className="pi pi-spin pi-spinner text-yellow-800" style={{ fontSize: '5em' }}></i>
      </div>}>
        <Services />
        <Specialties />
        <Benefits />
      </Suspense>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  toast: state.auth.toast
});

export default connect(mapStateToProps)(IndexPage);
