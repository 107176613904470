import React, { useEffect, useState } from 'react';
import truck from '../../images/truck.jpeg';
import EnquireForm from './EnquireForm';

const IntroHeader = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className={loading ? '' : 'px-2 py-6 md:px-4 lg:px-6'} id="header-bg"
    style={{ backgroundSize: 'cover', backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.5),rgba(0,0,0,0.1)), url(${truck})` }}>
      {loading ? <div style={{ height: '100vh' }} className="w-100 d-flex center bg-gray-900">
        <i className="pi pi-spin pi-spinner text-yellow-800" style={{ fontSize: '5em' }}></i>
      </div> : <>
        <div className="flex flex-wrap">
          <div className="w-12 lg:w-6 p-4 animate__animated animate__slideInRight">
            <h1 className="text-6xl font-bold text-white mt-0 mb-3">North East <span className="text-yellow-600">Express</span></h1>
            <p className="text-3xl text-gray-400 mt-0 mb-5">We offer quality dispatch service alongside Billing, Document Management, Safety & DOT Complaince, IFTA and Company Formation.</p>
            <ul className="list-none p-0 m-0">
              <li className="mb-3 flex align-items-center"><i className="pi pi-compass text-yellow-800 text-xl mr-2"></i><span className="text-gray-400 line-height-3">Carrier Set up.</span></li>
              <li className="mb-3 flex align-items-center"><i className="pi pi-compass text-yellow-800 text-xl mr-2"></i><span className="text-gray-400 line-height-3">SAFETY AND DOT Complaince.</span></li>
              <li className="mb-3 flex align-items-center"><i className="pi pi-map text-yellow-800 text-xl mr-2"></i><span className="text-gray-400 line-height-3">Company Formation.</span></li>
              <li className="mb-3 flex align-items-center"><i className="pi pi-calendar text-yellow-800 text-xl mr-2"></i><span className="text-gray-400 line-height-3">Document Management and Paperwork.</span></li>
              <li className="mb-3 flex align-items-center"><i className="pi pi-compass text-yellow-800 text-xl mr-2"></i><span className="text-gray-400 line-height-3">IFTA Filling.</span></li>
            </ul>
          </div>
          <div className="w-12 lg:w-6 text-center lg:text-right overflow-hidden d-flex center animate__animated animate__slideInLeft">
            <div className="p-4 shadow-6 border-round" style={{ maxWidth: '350px', minHeight: '550px', background: 'rgba(0,0,0,.5)' }}>
              <h1 className="text-6xl font-bold text-white mt-0 mb-3 text-left">Enquire</h1>
              <div style={{ height: '150px' }} className="">
                <EnquireForm />
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};

export default IntroHeader;
